"use client";

import Link from "next/link";
import styles from "../not-found-component/not-found-component.module.scss";
import { useEffect } from "react";
import { sendErrorRequest } from "@/utils/api/send-error";
export const ErrorComponent = ({
  error
}: {
  error: Error;
}) => {
  useEffect(() => {
    const url = window.location.href;
    if (window.location.origin === 'http://localhost:3000') return;
    // "Ошибка 500 на стороне клиента: " + url + " " + error + " " + `Прошлый url: ${document.referrer}`
    // sendErrorRequest(url, error, document.referrer);
    sendErrorRequest("Ошибка 500 на стороне клиента: " + url + " error: " + error + " " + `Прошлый url: ${document.referrer}`).then();
  }, []);
  return <section data-sentry-component="ErrorComponent" data-sentry-source-file="error-component.tsx">
      <div className="container">
        <div className={styles.content}>
          <div className={styles.textContent}>
            <h2 className={styles.subtitle}>
              <span>Упс...</span>
              <br />
              Что-то пошло не так, <span>ошибка: 500</span>
            </h2>
            <p className={styles.text}>
              Пожалуйста, попробуйте{" "}
              <span>обновить страницу или попробуйте позже</span>
            </p>
            <Link prefetch={false} href={"/"} className={styles.link} data-sentry-element="Link" data-sentry-source-file="error-component.tsx">
              Вернуться на главную
            </Link>
          </div>
          <div className={styles.imgWrap}>
            <h1 className={styles.title}>500</h1>
          </div>
        </div>
      </div>
    </section>;
};